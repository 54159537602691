<template>
  <div class="richmond"  @scroll="handleScroll">
    <m-header-richmond title="Night Market Coupon"></m-header-richmond>
    <div class="bg"></div>
    <div class="main">
      <div class="main-head">
        <div class="head_top">
          *Please Show this Page to the Vendor to Verify the Coupon 
        </div>
        <div class="couponImg">
          <img :class="$route.query.state == 1 ? 'item-pic-grey' : ''" v-if="tabledate.coupon_info" :src="tabledate.coupon_info.pic_link.url" alt="">
          <div v-if="$route.query.state == 1">USED</div>
        </div>
        <div class="head_time">
          <div>
            {{date.ymd}}
          </div>
         <div>
            {{date.hms}}
          </div>
        </div>
        <img class="markerImg" src="@/assets/richmond/Redeemed.png" alt="">
      </div>
      <div class="shops">
        <div class="shop_title">
          MOOBY HOT SALE
        </div>
         <!-- 为您推荐 -->
        <!-- <m-divider title="Recommended" class="m-cat-devider" v-if="!isEdit"></m-divider> -->
        <!-- 为你推荐 -->
        <goods-columns @cartClick="handleCart" :data="remGoodsList" class="m-cat-padding" v-if="!isEdit"></goods-columns>
        <p class="search-no" v-if='noMore'>No More Products</p>  
        <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw" v-if="!isEdit"></van-loading>
        <!-- 选择SKU 加入购物车 -->
        <m-sku :title="skuTitle" :show="skuModal" @close="val => skuModal=val" @success="handleCartSuccess" :goodsId.sync='goodsId'
        :barcode.sync="goodsBarCode" :num.sync="goodsNum" @edit="changeSku"></m-sku>
        <!-- 加入购物车动画 -->
        <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>
      </div>
    </div>
  </div>
</template>

<script>
import MHeaderRichmond from "@/components/en/m-header-richmond.vue";
import GoodsColumns from '@/components/en/goods-columns.vue'
import MSku         from '@/components/en/m-sku.vue'
import MAnimate     from '@/components/en/cart-animate.vue'
import { getActiveType } from '@/api/en/gift.js'
import { getCart,  updateCart } from '@/api/zh/cart.js'
import { use_info } from "@/api/en/night_market.js";
export default {
  name: "Index",
  components: { MHeaderRichmond, GoodsColumns, MSku, MAnimate},
  data() {
    return {
      date: {
          hms: '',
          ymd: ''
      },
      result: [],
      show: true,
      remGoodsList: [],
      skuTitle    : 'Add to Cart',
      skuModal    : false,    // 控制显示 选择sku加入购物车面板
      goodsId     : '',
      goodsBarCode: '',
      goodsNum    : 1,
      noMore      : false,
      isEdit      : false,
      start       : false,      // 是否开启加入购物车动画
      start_dom   : null,   // 开启动画的起点DOM 
      page        : 1,
      loading     : false,
      tabledate   : {}
    };
  },
  methods: {
    getData() {
      use_info({b_id: this.$route.query.b_id}).then(res => {  
          if(res){
            this.tabledate = res.data;
            let hms = res.data.updated_at.split(" ")[1].toString();
            console.log(hms)
            let  h = hms.split(":")[0]
            let  s = hms.split(":")[2]
            let  m = hms.split(":")[1]
            this.date.hms = h + ":"+ m + ":" + s
            let ymd = res.data.updated_at.split(" ")[0].toString();
            let year = ymd.split("-")[0].match(/.{2}$/);
            let month = ymd.split("-")[1]
            let day = ymd.split("-")[2]
            this.date.ymd =   month+ "/" + day + "/" + year;
          }
      })
    },
        handleScroll(e){
        if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
            if(!this.islock && !this.noMore){
                e.preventDefault()
                e.stopPropagation()
                this.loading = true
                this.page++
                this.getCartRemGoodsHandle()
            }
        }
    },
    getCartRemGoodsHandle() {
        // 获取推荐的商品
        this.islock   = true
        getActiveType({page: this.page}).then(res => {
            this.loading  = false
            this.islock   = false
            if(res) {
                this.remGoodsList = this.remGoodsList.concat(res.data.data)
            }else{
                this.noMore = true
            }
        })
    },
    handleCart(goods, e){
        // 商品列表购物车点击事件
        this.skuTitle    = 'Add to Cart'
        this.goodsId     = goods
        this.skuModal    = true
        this.start_dom   = e 
        this.startAnimat = true
        this.isAddCart   = true
        this.getCartHandle();
    },
    handleCartEnd(){
        // 加入购物车动画结束
        this.start = false
    },
    handleCartSuccess(){
        // sku 弹窗加入购物车成功回调
        this.skuModal = false
        if(this.startAnimat) {
            let timer = setTimeout( () => {
                this.start = true

                // 重新获取购物车
                if(this.isAddCart) {
                    this.getCartHandle()
                }
                clearTimeout(timer)
            },300)
        }        
    },
    changeSku(sku) {
        updateCart(this.cartId, {buy_number: sku.num, barcode: sku.barcode}).then(res => {
            if(res) {
                this.$notify({ type: 'success', message: res.msg, })
                this.getCartHandle()
                this.skuModal = false
            }else{
                this.$notify({ type: 'warning', message: res.msg, })
            }
        })
    },
    getCartHandle() {
        // 获取购物车数据  {city: this.address ? this.address.split(' ').join('') : null}
        this.$store.commit('load')
        getCart({city: this.address}).then(res => {
            if(res.code == 20000) {
                this.cart = res.data
                this.allTotal = 0
                this.cart.forEach( (el)  => {
                    el.goods_list.forEach( ele => {
                        this.$set(ele,'select',false)
                    })
                    this.select.push({storeId: el.id, select: false, goods: el.goods_list, selectPrice: 0})
                })
                this.$forceUpdate()
            } else {
                this.allTotal = 0
            }
        }).catch(() => {
            this.cart = []
        }).finally(() => {
            this.$store.commit('unload')
        })
    },
  },
  created() {
    if (this.$route.query.state == 1) {
      this.getData();
    }else{
      use_info({b_id: this.$route.query.b_id}).then(res => {  
          if(res){
            this.tabledate = res.data;
            this.timer = setInterval(() => {
                let date = new Date(); // 修改数据date
                let h = date.getHours();
                let m = date.getMinutes();
                let s = date.getSeconds();
                h = (h > 9) ? h : ("0" + h);
                s = (s < 10) ? ("0" + s) : s;
                m = (m < 10) ? ("0" + m) : m;
                this.date.hms = h + ":"+ m + ":" + s
                let year = date.getFullYear().toString().match(/.{2}$/);
                let month = date.getMonth() + 1;
                let day = date.getDate();
                this.date.ymd =   month+ "/" + day + "/" + year;
          }, 1000)
          }
      })
    
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getCartRemGoodsHandle()
    })
  },
  beforeRouteLeave(to, from , next) {
    if (to.path == '/richmond/coupon' && localStorage.getItem('coupon') <= 2) {
      if (!localStorage.getItem('coupon')) {
        localStorage.setItem('coupon', 1)
      }else{
        let coupon = localStorage.getItem('coupon');
        coupon ++;
        localStorage.setItem('coupon', coupon)
      }
    }
    next();
  }
};
</script>

<style scoped lang="less">
.richmond {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  font-size: 16px;
  font-family: FuturaBT-BoldItalic;
  position: relative;
  .bg {
    width: 100%;
    height: 470px;
    background: url("../../../assets/richmond/couponbg.png") no-repeat;
    background-size: 100%;
    position: absolute;
    top: 44px;
    left: 0;
    z-index: 2;
  }
  .main {
    width: 100%;
    background: url("../../../assets/richmond/point.png") #249FF2;
    background-size: 100%;
    position: relative;
    top: -45px;
    padding-top: 120px;
    .main-head {
      background-image: url("../../../assets/richmond/Group960.png");
      background-size: 100% 100%;
      width: 339px;
      height: 391px;
      margin: auto;
      padding: 15px 20px 20px;
      position: relative;
      z-index: 3;
      .markerImg {
        position: absolute;
        width: 132px;
        height: 117px;
        right: 0;
        bottom: 0;
        transform-origin: 50% 50%;
        animation: gan 0.5s;
      }
      @keyframes  gan{
        from{
          transform: scale(5);
          opacity: 0;
          filter:  blur(5px);
        }
        to {
          transform: scale(1);
          opacity: 1;
          filter:  blur(0px);
        }
      }
      .head_top {
          .flex{
            display: flex;
            justify-content: center;
          }
          height: 65px;
          padding: 5px 0;
          border-bottom: 1px dashed #030424;
        .headImg {
          display: block;
          border-radius: 50%;
          border: 1px solid #030424;
          width: 80px;
          height: 80px;
        }
          .head_content {
            height: 80px;
            margin-left: 15px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            p {
              padding-left: 4px;
              display: block;
              height: 25px;
              font-size: 18px;
            }
            div {
              line-height: 28px;
              width: 100%;
              img {
                width: 24px;
                height: 24px;
                display: block;
              }
            }
        }
      }
      .head_bot {
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div {
          text-align: center;
          font-size: 48px;
          line-height: 56px;
          font-weight: 600;
          font-style: italic;
        }
        p {
          font-size: 16px;
          line-height: 50px;
          text-align: center;
        }
      }
      .couponImg {
        width: 266px;
        height: 98px;
        margin: 20px auto 0;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
        .item-pic-grey {
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          -ms-filter: grayscale(100%);
          -o-filter: grayscale(100%);
          filter: grayscale(100%);
          filter: gray;
        }
        div {
              position: absolute;
              top: 0;
              left: 0;
               width: 266px;
              height: 98px;
              text-align: center;
              line-height: 98px;
              font-size: 35px;
              color: red;
              font-weight: 600;
            }
      }
      .head_time {
        div {
          text-align: center;
          font-weight: 600;
          font-size: 40px;
          line-height: 50px;
          &:nth-child(1) {
            margin-top: 10px;
            font-size: 35px;
          }
        }
      }
    }
    .shops {
      .shop_title {
        margin: 20px 0;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #181818;
        position: relative;
        z-index: 44;
      }
    }
  }
  .search-no{line-height:80px;text-align:center;color:#888;font-size:14px}
}
</style>